<template>
  <div class="container">
    <router-view></router-view>
  </div>
</template>

<script>
const AV = require("leancloud-storage");
AV.init({
    appId: "zuoag0JKVCPgV79wBH4Qw7j7-gzGzoHsz",
    appKey: "ScdU2M07T39cz7KYnRgNU8ZB",
    serverURL: 'https://zuoag0jk.lc-cn-n1-shared.com'
});

export default {
  name: 'App',
}
</script>

<style>

html,
body {
  height: 100%;
  width: 100%;
}
.container{
  display: flex;
  height: 100vh;
}
</style>
