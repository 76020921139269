import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import ArcoVue from '@arco-design/web-vue';
import App from './App.vue';
import Login from './pages/Login.vue'
import Index from './pages/Index.vue'
import Dashboard from './pages/Dashboard.vue'
import SemesterListPage from './pages/semester/SemesterListPage.vue'
import SemesterInfoPage from './pages/semester/SemesterInfoPage.vue'
import ClassListPage from './pages/class/ClassListPage.vue'
import ClassAddPage from './pages/class/ClassAddPage.vue'
import IndexTemplate from './components/IndexTemplate.vue'
import '@arco-design/web-vue/dist/arco.css';

const routes = [
    { path: '/', component: Index },
    { path: '/login', component: Login },
    {
        path: '/app', 
        component: IndexTemplate,
        children: [{
            path:'class/list',
            component:ClassListPage
        },{
            path:'class/add',
            component:ClassAddPage
        },{
            path:'semester/list',
            component:SemesterListPage
        },{
            path:'semester/add',
            component:SemesterInfoPage,
        },
        { path: '', component: Dashboard }]
    },
    
    // { path: '/semester/list', component: SemesterListPage },
    // { path: '/class/list', component: ClassListPage },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

const app = createApp(App);
app.use(router);
app.use(ArcoVue);
app.mount('#app');