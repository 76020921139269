<template>
    <a-space direction="vertical" fill>
        <a-typography-title :heading="5">
            添加新班级
        </a-typography-title>
        <a-form :model="classForm" :style="{ width: '600px' }" @submit="handleSelect">
            <a-form-item field="name" label="名称"
                :rules="[{ required: true, message: '必填' }, { maxLength: 20, message: '5-20字符' }, { minLength: 5, message: '5-20字符' }]">
                <a-input v-model="classForm.name" placeholder="请输入班级名称" />
            </a-form-item>
            <a-form-item field="semester" label="学期" :rules="[{ required: true, message: '必填' }]">
                <a-select :style="{ width: '320px' }" placeholder="请选择" v-model="classForm.semester">
                    <a-option v-for="(s,i) in semesterList" :key="i" :value="s">{{s}}</a-option>
                </a-select>
            </a-form-item>
            <a-form-item field="description" label="说明" :rules="[{ maxLength: 200, message: '200字以内' }]">
                <a-textarea placeholder="请输入班级说明（可选）" allow-clear />
            </a-form-item>
            <a-form-item>
                <a-button html-type="submit">{{ id ? '保存' : '添加' }}</a-button>
            </a-form-item>
        </a-form>
    </a-space>
</template>
<script>
import { reactive,ref } from 'vue';
const AV = require("leancloud-storage");
import { useRouter } from 'vue-router'
import { Notification } from '@arco-design/web-vue';
export default {
    name: 'ClassAddPage',
    props: ['id'],
    components: {

    },
    setup() {
        const router = useRouter()
        const classForm = reactive({
            name: '',
            semester: "",
            info: {
                description: ''
            }
        })
        const semesterList= ref([])
        const classId = router.currentRoute.value.params.id

        function getClassInfo() {
            if (classId) {
                // 获取
            }

        }

        function addClass() {
            const SchoolClass = AV.Object.extend("SchoolClass");
            const schoolClass = new SchoolClass()
            schoolClass.set("name", classForm.name)
            schoolClass.set("semester",classForm.semester)
            schoolClass.set("createdBy", AV.User.current().id)
            schoolClass.set("info", classForm.info)
            schoolClass.save().then(
                () => {
                    // 成功保存之后，执行其他逻辑
                    Notification.success({
                        title: '操作成功',
                        content: `班级保存成功。`,
                        closable: true,
                        style: { width: '500px' }
                    })
                    router.push('/app/class/list')
                },
                () => {
                    // 异常处理
                    Notification.error({
                        title: '操作失败',
                        content: `未能创建新班级`,
                        closable: true,
                        style: { width: '500px' }
                    })
                }
            );
        }
        function updateClass() {

        }
        const handleSelect = ({ values, errors }) => {
            if (errors) return
            if (classId) {
                updateClass(values)
            }
            else {
                addClass(values)
            }
        }
        function getSemesterList() {
            // 获取学期列表
            const query = new AV.Query("Semester");
            query.equalTo("createdBy", AV.User.current().id);
            query.find().then((semesters) => {
                for(let s of semesters){
                    semesterList.value.push(s.get("name"))
                }

            },(error)=>{
                console.log(error)
            });
        }
        getSemesterList()
        return { classForm, getClassInfo, addClass, updateClass, classId, handleSelect,semesterList }
    }
}
</script>