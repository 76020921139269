<template>
    <a-result style="height: 100%;" :status="null" title="欢迎使用" subtitle="请点击左侧菜单进入对应功能">
        <template #icon>
            <IconFaceSmileFill />
        </template>
    </a-result>
</template>
<script>
import { IconFaceSmileFill } from '@arco-design/web-vue/es/icon';
export default {
    name: 'DashboardPage',
    components: {
        IconFaceSmileFill
    }
}
</script>