<template>
    <a-layout style="height:100%">
        <a-layout-header>
            <a-page-header title="智慧点名册" subtitle="快速登分、成绩统计 v0.1" :show-back=false />
        </a-layout-header>
        <a-layout-content>
            <a-space direction="vertical" align="center" style="width:100%">
                <a-card title="登录" :style="{ width: '400px', marginBottom: '20px', marginTop: '100px' }">
                    <a-form :model="form" layout="horizontal">
                        <a-form-item field="username" label="用户名"
                            :rules="[{ required: true, message: '用户名为必填项' }, { minLength: 5, message: '长度为5-10字符' }, { maxLength: 10, message: '长度为5-10字符' }]">
                            <a-input v-model="form.username" placeholder="用户名" />
                        </a-form-item>
                        <a-form-item field="password" label="密码"
                            :rules="[{ required: true, message: '密码为必填项' }, { minLength: 8, message: '长度为8-20字符' }, { maxLength: 20, message: '长度为8-20字符' }]">
                            <a-input-password v-model="form.password" placeholder="请输入密码" />
                        </a-form-item>
                        <a-form-item v-if="errorMsg">
                            <a-alert type="error">{{ errorMsg }}</a-alert>
                        </a-form-item>
                        <a-form-item>
                            <a-space>
                                <a-button type="primary" @click="login">登录</a-button>
                                <!-- <a-button @click="register">注册</a-button> -->
                            </a-space>
                        </a-form-item>
                    </a-form>
                </a-card>
            </a-space>


        </a-layout-content>
    </a-layout>
</template>
  
<script>
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router'
const AV = require("leancloud-storage");

export default {
    name: 'LoginPage',
    setup() {
        const router = useRouter()
        if (AV.User.current()) {
            console.log(AV.User.current().id)
            router.push("/")
        }
        const form = reactive({
            username: '',
            password: '',
        })

        const errorMsg = ref()

        // function register() {

        //     const user = new AV.User();
        //     user.setUsername(form.username);
        //     user.setPassword(form.password);
        //     user.signUp().then(
        //         () => {
        //             // 注册成功
        //             alert(`注册成功，请使用用户名和密码登录！`);
        //         },
        //         () => {
        //             // 注册失败（通常是因为用户名已被使用）
        //             errorMsg.value = "注册失败，请重试"
        //         }
        //     );

        // }
        function login() {
            const user = new AV.User();
            user.setUsername(form.username);
            user.setPassword(form.password);
            user.logIn().then(() => {
                console.log("登录成功")
                router.push("/")
            }, () => {
                errorMsg.value = "登录失败，请重试"
            })

        }

        return {
            form, errorMsg, login
        }
    },
}
</script>