<template>
    <a-space direction="vertical" fill>
        <a-typography-title :heading="5">
            班级列表
        </a-typography-title>
        <a-table :columns="columns" :data="data" >
            <template #options>
                <a-button>编辑信息</a-button><a-button>学生报名</a-button>
            </template>
        </a-table>
        <a-button @click="$router.push('/app/class/add')">添加班级</a-button>
    </a-space>
</template>
<script>

import { ref, reactive } from 'vue';
const AV = require("leancloud-storage");
export default {
    name: 'ClassListPage',
    components: {

    },
    setup() {
        const classList = ref([])
        const columns = [
            {
                title: '班级名称',
                dataIndex: 'name',
            },
            {
                title: '创建时间',
                dataIndex: 'createdAt',
            },
            {
                title: '学生人数',
                dataIndex: 'studentCount',
            },{
                title:'操作',
                slotName:'options'
            },
        ];
        const data = reactive([])
        function getClassList() {
            // 获取学期列表
            const query = new AV.Query("SchoolClass");
            query.equalTo("createdBy", AV.User.current().id);
            query.find().then((classes) => {
                for(let c of classes){
                    data.push({
                        name:c.get("name"),
                        createdAt: c.createdAt.toLocaleString(),
                        studentCount:c.get("studentList")?c.get("studentList").length:0
                    })
                }

            },(error)=>{
                console.log(error)
            });
        }
        getClassList()
        return { classList, getClassList, columns, data }
    }
}
</script>