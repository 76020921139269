<template>
    <a-space direction="vertical" fill>
        <a-typography-title :heading="5">
            学期列表
        </a-typography-title>
        <a-table :columns="columns" :data="data" />
        <a-button @click="$router.push('/app/semester/add')">添加学期</a-button>
    </a-space>
</template>
<script>
import { ref, reactive } from 'vue';
const AV = require("leancloud-storage");
export default {
    name: 'SemesterListPage',
    components: {

    },
    setup() {
        const semesterList = ref([])
        const columns = [
            {
                title: '学期名称',
                dataIndex: 'name',
            },
            {
                title: '起始时间',
                dataIndex: 'startTime',
            },
            {
                title: '结束时间',
                dataIndex: 'endTime',
            },
        ];
        const data = reactive([])
        function getSemesterList() {
            // 获取学期列表
            const query = new AV.Query("Semester");
            query.equalTo("createdBy", AV.User.current().id);
            query.find().then((semesters) => {
                console.log(semesters.length)
                for(let s of semesters){
                    console.log(s)
                    data.push({
                        name:s.get("name"),
                        startTime: s.get("startTime"),
                        endTime:s.get("endTime")
                    })
                }

            },(error)=>{
                console.log(error)
            });
        }
        function addSemester() {

        }
        getSemesterList()
        return { semesterList, getSemesterList, addSemester, columns, data }
    }
}
</script>