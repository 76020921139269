<template>
  <a-layout>
    <a-layout-header>
      <a-page-header title="智慧点名册" subtitle="快速登分、成绩统计 v0.1" :show-back=false>
        <template #extra>
          <a-dropdown @select="handleSelect" trigger="hover">
            <a-button>{{ userName }}<icon-down /></a-button>
            <template #content>
              <a-doption>退出登录</a-doption>
            </template>
          </a-dropdown>
        </template>
      </a-page-header>
    </a-layout-header>
    <a-layout>
      <a-layout-sider>
        <div style="box-sizing: border-box;">
          <a-menu :style="{ width: '200px', height: '100%' }" :default-open-keys="['0']" :default-selected-keys="['0_2']">
          <a-sub-menu key="0">
            <template #icon><icon-apps></icon-apps></template>
            <template #title>Navigation 1</template>
            <a-menu-item key="0_0">Menu 1</a-menu-item>
            <a-menu-item key="0_1">Menu 2</a-menu-item>
            <a-menu-item key="0_2">Menu 3</a-menu-item>
            <a-menu-item key="0_3">Menu 4</a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="1">
            <template #icon><icon-bug></icon-bug></template>
            <template #title>Navigation 2</template>
            <a-menu-item key="1_0">Menu 1</a-menu-item>
            <a-menu-item key="1_1">Menu 2</a-menu-item>
            <a-menu-item key="1_2">Menu 3</a-menu-item>
          </a-sub-menu>
          <a-sub-menu key="2">
            <template #icon><icon-bulb></icon-bulb></template>
            <template #title>Navigation 3</template>
            <a-menu-item key="2_0">Menu 1</a-menu-item>
            <a-menu-item key="2_1">Menu 2</a-menu-item>
            <a-sub-menu key="2_2" title="Navigation 4">
              <a-menu-item key="2_2_0">Menu 1</a-menu-item>
              <a-menu-item key="2_2_1">Menu 2</a-menu-item>
            </a-sub-menu>
          </a-sub-menu>
        </a-menu>
        </div>
        
      </a-layout-sider>
      <a-layout-content>

        <div>页面内容</div>
      </a-layout-content>

    </a-layout>

  </a-layout>
</template>
  
<script>
const AV = require("leancloud-storage");
import { useRouter } from 'vue-router'
import { IconDown } from '@arco-design/web-vue/es/icon';
import { ref } from 'vue';
export default {
  name: 'IndexPage',
  components: {
    IconDown
  },
  setup() {
    const router = useRouter()
    const userName = ref();
    if (!AV.User.current()) {
      router.push("/login")
    }
    else {
      router.push("/app")
      userName.value = AV.User.current().getUsername()
    }
    function handleSelect(value) {
      if (value == "退出登录") {
        AV.User.logOut();
        router.push("/login")
      }
    }
    return { handleSelect, userName }
  }
}
</script>