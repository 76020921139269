<template>
    <a-space direction="vertical" fill>
        <a-typography-title :heading="5">
            添加新学期
        </a-typography-title>
        <a-form :model="semesterForm" :style="{ width: '600px' }" @submit="handleSelect">
            <a-form-item field="name" label="名称"
                :rules="[{ required: true, message: '必填' }, { maxLength: 20, message: '5-20字符' }, { minLength: 5, message: '5-20字符' }]">
                <a-input v-model="semesterForm.name" placeholder="请输入学期名称" />
            </a-form-item>
            <a-form-item field="timeRange" label="时间范围" :rules="[{ required: true, message: '必填' }]">
                <a-range-picker v-model="semesterForm.timeRange" />
                <br />
            </a-form-item>
            <a-form-item>
                <a-button html-type="submit">{{ id ? '保存' : '添加' }}</a-button>
            </a-form-item>
        </a-form>
    </a-space>
</template>
<script>
import { reactive } from 'vue';
const AV = require("leancloud-storage");
import { useRouter } from 'vue-router'
import { Notification } from '@arco-design/web-vue';
export default {
    name: 'SemesterInfoPage',
    props: ['id'],
    components: {

    },
    setup() {
        const router = useRouter()
        const semesterForm = reactive({
            name: '',
            timeRange: []
        })
        const semesterID = router.currentRoute.value.params.id

        function getSemesterInfo() {
            if (semesterID) {
                // 获取
            }

        }

        function addSemester() {
            const Semester = AV.Object.extend("Semester");
            const semester = new Semester()
            semester.set("name", semesterForm.name)
            semester.set("startTime", semesterForm.timeRange[0])
            semester.set("endTime", semesterForm.timeRange[1])
            semester.set("createdBy", AV.User.current().id)
            semester.save().then(
                () => {
                    // 成功保存之后，执行其他逻辑
                    Notification.success({
                        title: '操作成功',
                        content: `“${semesterForm.name}”学期保存成功。`,
                        closable: true,
                        style: { width: '500px' }
                    })
                    router.push('/app/semester/list')
                },
                () => {
                    // 异常处理
                    Notification.error({
                        title: '操作失败',
                        content: `未能创建新学期`,
                        closable: true,
                        style: { width: '500px' }
                    })
                }
            );
        }
        function updateSemester() {

        }
        const handleSelect = ({ values, errors }) => {
            if (errors) return
            if (semesterID) {
                updateSemester(values)
            }
            else {
                addSemester(values)
            }
        }
        return { semesterForm, getSemesterInfo, addSemester, updateSemester, semesterID, handleSelect }
    }
}
</script>